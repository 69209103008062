import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography } from '@mui/material';
import type { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logout from '../LogoutButton';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { AuthStateType, ROLES, useAuthState } from 'src/contexts/authContext';

import FolderOpenIcon from '../../icons/FolderOpen';
import CheckCircle from '../../icons/CheckCircle';
import UsersIcon from '../../icons/Users';
import Mail from '../../icons/Mail';
import Archive from '../../icons/Archive';
import DeviceTablet from 'src/icons/DeviceTablet';
import GuestModeIcon from '../../icons/GuestModeIcon';
import GeneralIcon from '../../icons/General';
import DemoAccessIcon from '../../icons/DemoAccess';
import AudioGuidesIcon from '../../icons/AudioGuides';
import ThemeIcon from '../../icons/Theme';
import BandSettingsIcon from '../../icons/BandSettings';
import InformationCircle from 'src/icons/InformationCircle';
import DotsHorizontal from 'src/icons/DotsHorizontal';
import Moon from 'src/icons/Moon';
import ChartPie from 'src/icons/ChartPie';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = (authState: AuthStateType) => [
  {
    title: 'General',
    items: [
      (authState.minAuthLevelMet(ROLES.CSA) ||
        authState.isRole(ROLES.DEV_ADMIN)) && {
        title: 'Find Users',
        path: '/dashboard/users/details',
        icon: <UsersIcon fontSize="small" />
      },
      {
        title: 'Find Orders',
        icon: <Archive fontSize="small" />,
        path: '/dashboard/orders/list'
      }
    ]
  },
  {
    title: 'Configuration',
    items: [
      authState.minAuthLevelMet(ROLES.CSA_ADMIN) && {
        title: 'Sens.ai Mobile App',
        icon: <DeviceTablet fontSize="small" />,
        children: [
          {
            title: 'App Store',
            path: '/dashboard/configuration',
            icon: <InformationCircle fontSize="small" />
          },
          {
            title: 'Health Check',
            path: 'healthcheck',
            icon: <CheckCircle fontSize="small" />
          },
          {
            title: 'Guest Mode',
            path: 'guestmode-config',
            icon: <GuestModeIcon fontSize="small" />
          },
          {
            title: 'Free Mode',
            path: 'free-mode-config',
            icon: <GuestModeIcon fontSize="small" />
          },
          {
            title: 'General',
            path: 'general-config',
            icon: <GeneralIcon fontSize="small" />
          },
          {
            title: 'Demo Access',
            path: 'demo-access-config',
            icon: <DemoAccessIcon fontSize="small" />
          },
          {
            title: 'Audio Guides',
            path: 'audio-guides-config',
            icon: <AudioGuidesIcon fontSize="small" />
          },
          {
            title: 'Theme',
            path: 'theme-config',
            icon: <ThemeIcon fontSize="small" />
          },
          {
            title: 'Band Settings',
            path: 'band-settings-config',
            icon: <BandSettingsIcon fontSize="small" />
          },
          {
            title: 'App Text',
            path: 'app-text-config',
            icon: <DotsHorizontal fontSize="small" />
          },
          {
            title: 'Overlay Tutorial',
            path: 'overlay-tutorial-config',
            icon: <Moon fontSize="small" />
          }
        ]
      },
      authState.minAuthLevelMet(ROLES.CSA) && {
        title: 'Email Templates',
        icon: <Mail fontSize="small" />,
        path: '/dashboard/emails'
      },
      authState.hasAuthRole([ROLES.DEV_ADMIN, ROLES.ADMIN]) && {
        title: 'Data Tools',
        icon: <ChartPie fontSize="small" />,
        path: '/dashboard/data-tools',
        children: [
          {
            title: 'Erp Annotation',
            path: '/dashboard/data-tools/erp-annotation'
          }
        ]
      },
      authState.minAuthLevelMet(ROLES.CSA_MANAGER) && {
        title: 'Order Management',
        icon: <FolderOpenIcon fontSize="small" />,
        path: '/dashboard/orders',
        children: [
          authState.minAuthLevelMet(ROLES.CSA_MANAGER) && {
            title: 'Import',
            path: '/dashboard/orders/import'
          },
          // {
          //   title: 'Confirm Addresses',
          //   path: '/dashboard/orders/confirm-addressess'
          // },
          authState.minAuthLevelMet(ROLES.CSA_ADMIN) && {
            title: 'Shipping Tiers',
            path: '/dashboard/inventory/shippingtiers'
          },
          {
            title: 'Coupons',
            path: '/dashboard/coupons'
          }
        ]
      }
    ]
  }
];

const getEnvironment = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('admindev')) {
    return 'DEVELOPMENT';
  }
  if (hostname.includes('adminstaging')) {
    return 'STAGING';
  }
  if (hostname.includes('localhost')) {
    return 'LOCAL';
  }
  return '';
};

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const authState = useAuthState();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Box>
        <RouterLink to="/">
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'background.default',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'space-around',
                overflow: 'hidden',
                p: 2
              }}
            >
              <Box textAlign="center">
                <Typography color="textPrimary" variant="subtitle2">
                  Sens.ai Admin
                </Typography>
                {getEnvironment() && (
                  <Typography color="red" variant="h5">
                    {`${getEnvironment()}`}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </RouterLink>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections(authState).map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Logout />
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
