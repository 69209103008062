import React from 'react';
import { FC } from 'react';

import { Helmet } from 'react-helmet';
import { Box, Container, Grid, Typography } from '@mui/material';
import { ConfigItem } from '../../components/dashboard/config';
import useSettings from '../../hooks/useSettings';
import { gql } from '@apollo/client';
import API from '../../server/serverInterface';

enum AppConfigType {
  guestModeSettingsConfig = 'guestModeSettingsConfig',
  generalConfig = 'generalConfig',
  demoAccessConfig = 'demoAccessConfig',
  audioGuidesConfig = 'audioGuidesConfig',
  themeConfig = 'themeConfig',
  bandSettingsConfig = 'bandSettingsConfig',
  appTextConfig = 'appTextConfig',
  overlayTutorialConfig = 'overlayTutorialConfig',
  freeModeConfig = 'freeModeConfig'
}

let lastType: AppConfigType;

const Config: React.FC<{
  type: AppConfigType;
}> = ({ type }) => {
  const [appConfigData, setAppConfigData] = React.useState<String>(null);
  const [updateAppConfigData, setUpdateAppConfigData] =
    React.useState<boolean>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isUpdateLoading, setIsUpdateLoading] = React.useState(false);

  const getAppConfig = async (type: AppConfigType) => {
    return API.execute('healthcheck', 'GetAppConfig', {
      type: [type]
    })
      .then((apiData) => {
        setAppConfigData(apiData?.data?.GetAppConfig[0]?.value);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateAppConfig = async (type: AppConfigType, config: String) => {
    setIsUpdateLoading(true);
    console.log(type);
    console.log(config);
    return API.execute('healthcheck', 'UpdateAppConfig', {
      type: type,
      config: config
    })
      .then((apiData) => {
        setUpdateAppConfigData(apiData?.data?.UpdateAppConfig);
        setIsUpdateLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (lastType !== type) {
    setIsLoading(true);
    setIsUpdateLoading(false);
    getAppConfig(type);
  }
  lastType = type;

  const { settings } = useSettings();

  let title = 'Config';
  switch (type) {
    case AppConfigType.guestModeSettingsConfig:
      title = 'GuestMode';
      break;
    case AppConfigType.generalConfig:
      title = 'General';
      break;
    case AppConfigType.demoAccessConfig:
      title = 'Demo Access';
      break;
    case AppConfigType.audioGuidesConfig:
      title = 'Audio Guides';
      break;
    case AppConfigType.themeConfig:
      title = 'Theme';
      break;
    case AppConfigType.bandSettingsConfig:
      title = 'Band Settings';
      break;
    case AppConfigType.appTextConfig:
      title = 'App Text';
      break;
    case AppConfigType.overlayTutorialConfig:
      title = 'Overlay Tutorial';
      break;
    case AppConfigType.freeModeConfig:
      title = 'Free Mode';
      break;
  }

  return (
    <>
      <Helmet>
        <title>{`Dashboard: ${title} | Sens.ai`}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={1}
            item
            xs={12}
          >
            <Grid item>
              <Typography fontSize="" color="textPrimary" variant="h5">
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={1}
            item
            xs={12}
          >
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography color="textSecondary" variant="overline">
                {`${title} Config`}
              </Typography>
              <ConfigItem
                type={type}
                configData={appConfigData}
                isLoading={isLoading || isUpdateLoading}
                updateAppConfig={updateAppConfig}
                updateAppConfigData={updateAppConfigData}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export { Config, AppConfigType };
