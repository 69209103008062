import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/dashboard/DashboardLayout';
import MainLayout from './components/MainLayout';
import Overview from './pages/dashboard/Overview';
import HealthCheck from './pages/dashboard/HealthCheck';
import { Config, AppConfigType } from './pages/dashboard/Config';
import InventoryShippingTiers from './pages/dashboard/InventoryShippingTiers';
import UsersDetails from './pages/dashboard/UsersDetails';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import InventoryGoodsIn from './pages/dashboard/InventoryGoodsIn';
import OrderImports from './pages/dashboard/OrderImports';
import OrdersList from './pages/dashboard/OrdersList';
import EmailTemplates from './pages/dashboard/EmailTemplates';
import EmailTemplate from './pages/dashboard/EmailTemplate';
import Configuration from './pages/dashboard/Configuration';
import Coupons from './pages/dashboard/Coupons';
import ErpAnnotation from './components/dashboard/data-tools/erp-annotation';

const routes = [
  {
    path: 'dashboard',
    element: <DashboardLayout />,
    children: [
      {
        path: 'configuration',
        element: <Configuration />
      },
      {
        path: 'coupons',
        element: <Coupons />
      },
      {
        path: 'users/:id',
        element: <UsersDetails />
      },
      {
        path: 'overview',
        element: <Overview />
      },
      {
        path: 'healthcheck',
        element: <HealthCheck />
      },
      {
        path: 'guestmode-config',
        element: <Config type={AppConfigType.guestModeSettingsConfig} />
      },
      {
        path: 'free-mode-config',
        element: <Config type={AppConfigType.freeModeConfig} />
      },
      {
        path: 'general-config',
        element: <Config type={AppConfigType.generalConfig} />
      },
      {
        path: 'demo-access-config',
        element: <Config type={AppConfigType.demoAccessConfig} />
      },
      {
        path: 'audio-guides-config',
        element: <Config type={AppConfigType.audioGuidesConfig} />
      },
      {
        path: 'theme-config',
        element: <Config type={AppConfigType.themeConfig} />
      },
      {
        path: 'band-settings-config',
        element: <Config type={AppConfigType.bandSettingsConfig} />
      },
      {
        path: 'app-text-config',
        element: <Config type={AppConfigType.appTextConfig} />
      },
      {
        path: 'overlay-tutorial-config',
        element: <Config type={AppConfigType.overlayTutorialConfig} />
      },
      {
        path: 'users/details',
        element: <UsersDetails />
      },

      {
        path: 'orders/confirm-addressess',
        element: <InventoryGoodsIn />
      },
      {
        path: 'inventory/shippingtiers',
        element: <InventoryShippingTiers />
      },
      {
        path: 'orders/import',
        element: <OrderImports />
      },
      {
        path: 'orders/list',
        element: <OrdersList />
      },
      {
        path: 'emails',
        element: <EmailTemplates />
      },
      {
        path: 'emails/:id',
        element: <EmailTemplate />
      },
      {
        path: 'data-tools/erp-annotation',
        element: <ErpAnnotation />
      },
      {
        path: '*',
        element: <Navigate to="/404" />
      }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: 'login',
        element: <Login />
      },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/dashboard/overview" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
